"use client";

import { Box, Section } from "@hkexpressairwayslimited/ui";
import { cancelInitNskToken } from "modules/features/auth/actions/nskTokenAction";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { resetFlightBooking } from "store/sessionStorage/slices/flightBookingSlice";
import { resetMMBState } from "store/sessionStorage/slices/manageMyBookingSlice";
import { resetNskToken } from "store/sessionStorage/slices/nskTokenSlice";
import { BookingBox } from "ui/features/flight-booking/booking/BookingBox";

function Page() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(cancelInitNskToken());
    dispatch(resetNskToken());
    dispatch(resetFlightBooking());
    dispatch(resetMMBState());
  }, []);

  return (
    <Section>
      <Box sx={{ mt: "50px", mb: "300px" }}>
        <BookingBox />
      </Box>
    </Section>
  );
}

export default Page;
