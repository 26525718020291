import { useTransContent } from "modules/common/trans-content/transContent";
import { useMemo } from "react";

export default function useBookTripSelect() {
  const { t } = useTransContent();
  return useMemo(
    () => ({
      trip: t("web.home.bookATrip.trip"),
      passengers: t("web.home.bookATrip.passengers"),
      passengersAdult: t("web.home.bookATrip.passengers.adult"),
      passengersChild: t("web.home.bookATrip.passengers.child"),
      passengersChildTip: t("web.home.bookATrip.passengers.childTip"),
      passengersInfant: t("web.home.bookATrip.passengers.infant"),
      passengersInfantTip: t("web.home.bookATrip.passengers.infantTip"),
      passengersPopUpTitle: t("web.home.bookATrip.passengers.popUpTitle"),
      promoCodeOptional: t("web.home.bookATrip.promoCodeOptional"),
      date: t("web.home.bookATrip.date"),
      chooseTravelDates: t("web.home.bookATrip.chooseTravelDates"),
      departDate: t("web.home.bookATrip.departDate"),
      returnDate: t("web.home.bookATrip.returnDate"),
      departDateTrip1: t("web.home.bookATrip.departDateTrip1"),
      departDateTrip2: t("web.home.bookATrip.departDateTrip2"),
      search: t("web.home.bookATrip.search"),
      recentSearches: t("web.home.bookATrip.recentSearches"),
      multiCity: t("web.home.bookATrip.multiCity"),
      tripOptions: {
        roundTrip: t("web.home.bookATrip.roundTrip"),
        oneWay: t("web.home.bookATrip.oneWay"),
        multiCity: t("web.home.bookATrip.multiCity"),
      },
      fromRequired: t("web.home.bookATrip.from.required"),
      toRequired: t("web.home.bookATrip.from.toRequired"),
      dateRequired: t("web.home.bookATrip.dateRequired"),
      departDateRequired: t("web.home.bookATrip.departDate.required"),
      returnDateRequired: t("web.home.bookATrip.returnDate.required"),
      multiCityFrom1Required: t("web.home.bookATrip.multiCityFrom1.required"),
      multiCityTo1Required: t("web.home.bookATrip.multiCityTo1.required"),
      multiCityDepartDateRequired: t("web.home.bookATrip.multiCityDepartDate.required"),
      multiCityFrom2Required: t("web.home.bookATrip.multiCityFrom2.required"),
      multiCityTo2Required: t("web.home.bookATrip.multiCityTo2.required"),
      multiCityReturnDateRequired: t("web.home.bookATrip.multiCityReturnDate.required"),
      dateErrorMessage: t("web.home.bookATrip.dateErrorMessage"),
      from: t("web.home.bookATrip.from"),
      to: t("web.home.bookATrip.to"),
      airportCode: {
        HK: t("airportCodeName.HK"),
        HKG: t("airportCodeName.HKG"),
        CN: t("airportCodeName.CN"),
        FYG: t("airportCodeName.FYG"),
        NGB: t("airportCodeToCityName.NGB"),
        NSZ: t("airportCodeName.NSZ"),
        PFT: t("airportCodeName.PFT"),
        PKX: t("airportCodeName.PKX"),
        SYX: t("airportCodeName.SYX"),
        ZGN: t("airportCodeName.ZGN"),
        ZTI: t("airportCodeName.ZTI"),
        ZYK: t("airportCodeToCityName.ZYK"),
        JP: t("airportCodeName.JP"),
        FUK: t("airportCodeName.FUK"),
        HND: t("airportCodeName.HND"),
        KIX: t("airportCodeName.KIX"),
        KMJ: t("airportCodeName.KMJ"),
        KOJ: t("airportCodeName.KOJ"),
        NGO: t("airportCodeName.NGO"),
        OKA: t("airportCodeName.OKA"),
        NRT: t("airportCodeName.NRT"),
        TAK: t("airportCodeName.TAK"),
        TYO: t("airportCodeName.TYO"),
        KR: t("airportCodeName.KR"),
        CJU: t("airportCodeName.CJU"),
        ICN: t("airportCodeName.ICN"),
        PUS: t("airportCodeName.PUS"),
        PH: t("airportCodeName.PH"),
        TH: t("airportCodeName.TH"),
        BK1: t("airportCodeName.BK1"),
        BKK: t("airportCodeName.BKK"),
        KHH: t("airportCodeName.KHH"),
        DMK: t("airportCodeName.DMK"),
        CMX: t("airportCodeName.CMX"),
        HKT: t("airportCodeName.HKT"),
        TW: t("airportCodeName.TW"),
        RMQ: t("airportCodeName.RMQ"),
        TPE: t("airportCodeName.TPE"),
        CRK: t("airportCodeName.CRK"),
        MNL: t("airportCodeName.MNL"),
        CNX: t("airportCodeName.CNX"),
        VN: t("airportCodeName.CXR"),
        CXR: t("airportCodeName.CXR"),
        DAD: t("airportCodeName.DAD"),
        HAN: t("airportCodeName.HAN"),
      },
    }),
    [t]
  );
}
